{
	"app": {
		"empty_data": "No data",
		"currency": "som"
	},
	"theme": {
		"system": "System",
		"light": "Light",
		"dark": "Dark"
	},
	"errors": {
		"403": "You do not have enough rights",
		"404": "Not found"
	},
	"table": {
		"actions": "Actions",
		"no_data_text": "Nothing found",
		"search_placeholder": "Search",
		"id": "Id",
		"confirm_delete": "Delete row?"
	},
	"pagination": {
		"previous": "Previous",
		"next": "Next",
		"all": "All"
	},
	"editor": {
		"table": "Table",
		"text_formatting": "Text formatting",
		"add_column_before": "Add column before",
		"add_column_after": "Add column after",
		"column_delete": "Delete column",
		"add_row_before": "Add row before",
		"add_row_after": "Add row after",
		"row_delete": "Delete row",
		"merge_cells": "Merge cells",
		"split_cell": "Split cells",
		"insert_table": "Insert table",
		"bold": "Bold",
		"italic": "Italic",
		"strike": "Strikethrough",
		"paragraph": "Paragraph",
		"h1": "Heading 1",
		"h2": "Heading 2",
		"h3": "Heading 3",
		"bullet_list": "Bullet list",
		"ordered_list": "Ordered list",
		"clean": "Clear formatting",
		"align_left": "Align left",
		"align_right": "Align right",
		"align_center": "Align center",
		"toggle_header_cell": "Toggle header"
	},
	"menu": {
		"home": "Home",
		"projects": "Projects",
		"employees": "Employees",
		"objects": "Objects",
		"profile": "Profile",
		"wiki": "Wiki",
		"settings": "Settings"
	},
	"form": {
		"save": "Save",
		"cancel": "Cancel",
		"select_item": "Select value",
		"file_input": "Select file",
		"delete": "Delete",
		"files": "Attachments",
		"alert_submit": "Form submitted",
		"data_added": "Data added",
		"data_updated": "Data updated",
		"error": "Form contains errors",
		"deleted": "Data deleted",
		"internal_error": "An error occurred"
	},
	"snackbar": {
		"success": "Action successful",
		"error": "An error occurred"
	},
	"dialog": {
		"text": "Confirm action?",
		"confirm_button_text": "Yes",
		"cancel_button_text": "No",
		"add_button": "Add",
		"edit_button": "Edit",
		"add_title": "Add",
		"edit_title": "Edit",
		"save": "Save",
		"cancel": "Cancel"
	},
	"login": {
		"title": "Welcome to Q.Control",
		"subtitle": "Login to start working (you can use email or phone number)",
		"password": "Password",
		"forgot_password": "Forgot password?",
		"remember_me": "Remember me",
		"submit": "Login",
		"login": "Phone number",
		"login_placeholder": "+996705554433"
	},
	"forgot_password": {
		"title": "Forgot password?",
		"subtitle": "Enter your phone number or email and we'92ll send you a code",
		"send": "Send",
		"back_login": "Back to login",
		"login": "Phone number or email",
		"login_placeholder": "",
		"code_sent": "Code sent"
	},
	"reset_password": {
		"title": "Reset password",
		"new_password": "New password",
		"confirm_password": "Confirm new password",
		"update_password": "Update password",
		"back_login": "Back to login"
	},
	"two_steps": {
		"title": "Code confirmation",
		"sent_to_phone": "We sent the code to your phone number",
		"sent_to_email": "We sent the code to your email",
		"type_code": "Enter code",
		"submit": "Submit",
		"didnot_get_code": "Didn'92t receive code?",
		"resend": "Resend code",
		"login_save_error": "Something went wrong"
	},
	"project": {
		"project": "Project",
		"projects": "Projects",
		"title": "Title",
		"count": "Count",
		"objects_count": "Objects",
		"object_employee": "Responsible",
		"progress": "Progress",
		"date_start": "Start",
		"date_end": "End",
		"date_created": "Created",
		"date_start_full": "Start date",
		"date_end_full": "End date",
		"date_created_full": "Creation date",
		"search_placeholder": "Search project",
		"add_button": "Add",
		"add_dialog_title": "Add project",
		"status": "Status",
		"priority": "Priority",
		"supervision": "Supervision",
		"project_not_found": "Project not found",
		"branch": "Branch",
		"description": "Description",
		"delete": "Delete",
		"confirm_delete_text": "Delete project?",
		"edit": "Edit",
		"edit_project_info": "Edit project",
		"objects": "Objects",
		"PUD": "PUD",
		"customer": "Customer",
		"contract": "Contract",
		"files": "Attachments",
		"documents": "Documents",
		"methodology": "Methodology",
		"technical_plan": "Technical supervision plan",
		"team_distribution": "Team distribution",
		"social_standarts": "Environmental and social standards",
		"safety": "Health and safety",
		"projects_map": "Project map",
		"employees": "Employees",
		"add_employee": "Add employee"
	},
	"object": {
		"table_title": "Objects",
		"search_placeholder": "Search",
		"add_button": "Add",
		"add_dialog_title": "Add object",
		"title": "Title",
		"date_start": "Creation date",
		"date_end": "End",
		"warranty_end_date": "Warranty period end date",
		"status": "Status",
		"priority": "Priority",
		"supervision": "Supervision",
		"progress": "Progress",
		"address": "Address",
		"responsible": "Responsible",
		"select_type": "Type",
		"select_priority": "Priority",
		"object": "Object",
		"gpr": "GPR",
		"prescriptions": "Prescriptions",
		"reports": "Reports",
		"tasks": "Tasks",
		"settings": "Settings",
		"project_company": "Project company",
		"organization": "Contractor",
		"implementer": "Implementer",
		"delete": "Delete",
		"confirm_delete_text": "Delete object?",
		"edit_button": "Edit",
		"edit_dialog_title": "Edit object",
		"employee": "Responsible",
		"add_employee": "Add employee",
		"project": "Project",
		"map_title": "Object coordinates",
		"map_lat": "Latitude",
		"map_lng": "Longitude",
		"map_radius": "Radius",
		"settings_reports_previous_days": "Edit reports for previous days",
		"settings_daily_reports": "Supervision daily report editing",
		"settings_copy_work_types_from_other_object": "Copy from another object",
		"settings_work_types": "Work types",
		"settings_work_type": "Work type",
		"settings_work_category": "Category",
		"settings_work_title": "Title",
		"settings_work_cost": "Cost",
		"settings_work_duration": "Duration",
		"settings_work_start": "Start",
		"settings_work_actions": "Actions",
		"settings_planned_workers": "Workers",
		"settings_planned_equipment": "Number of equipment",
		"settings_row_kind": "Type",
		"settings_add_row": "Add row",
		"settings_edit_category": "Edit category",
		"settings_edit_work": "Edit work type",
		"settings_confirm_delete_row": "Confirm deletion?",
		"documents": "Documents",
		"avr": "AVR",
		"cumulative": "Cumulative",
		"checklists": "Checklists"
	},
	"avr": {
		"work_type": "Type of work",
		"created_at": "Created at",
		"employee": "Created by",
		"cost": "Cost",
		"files": "Files",
		"add_button": "Add",
		"add_edit": "Edit",
		"add_title": "Add AVR",
		"edit_title": "Edit AVR",
		"confirm_delete": "Delete AVR?",
		"delete": "Delete",
		"select_type": "Type",
		"work_category": "Category",
		"work_or_category": "Type of work or category"
	},
	"gpr": {
		"table_title": "Title",
		"table_duration": "Duration",
		"table_real_duration": "Actual Duration",
		"table_start": "Start",
		"table_end": "End",
		"table_completed": "%",
		"bar_completed": "Completion",
		"bar_outstending": "Delay",
		"bar_left": "On Schedule",
		"bar_future": "Not Started",
		"equipments_quantity": "Equipment Quantity",
		"workers_quantity": "Number of Workers",
		"planned_equipments_quantity": "Planned Equipment Quantity",
		"planned_workers_quantity": "Planned Number of Workers",
		"empty_data": "No Data"
	},
	"cumulative": {
		"table_title": "Title",
		"table_cost": "Planned",
		"table_avr_cost": "Actual",
		"table_start": "Start",
		"table_end": "End",
		"table_cost_percent": "%",
		"chart_actual": "Actual",
		"chart_planned": "Planned",
		"chart_remainder": "Remainder",
		"chart_excess": "Excess",
		"total_actual": "Actual",
		"total_planned": "Planned"
	},
	"report": {
		"work_type": "Work Type",
		"created_at": "Created",
		"workers": "Workers",
		"equipment": "Equipment",
		"status": "Status",
		"search_by_id": "Search by Id",
		"add_button": "Add",
		"edit_button": "Edit",
		"edit_title": "Edit Report",
		"add_title": "Add Report",
		"completed": "Completed",
		"files": "Files",
		"actual_cost": "Actual Cost"
	},
	"document": {
		"title": "Title",
		"created_at": "Created",
		"add_title": "Add Document",
		"add_button": "Add",
		"edit_title": "Edit Report",
		"edit_button": "Edit",
		"date": "Date",
		"date_start": "Start Date",
		"date_end": "End Date",
		"search_by_title": "Search by Title",
		"content": "Content",
		"parameters": "Parameters",
		"print_button": "Print"
	},
	"customer": {
		"coordinator": "Project Coordinator",
		"contact_person": "Contact Person",
		"address": "Address",
		"contacts": "Contact Details",
		"edit": "Edit",
		"dialog_title": "Edit Customer",
		"name": "Name",
		"contact_person_post": "Position"
	},
	"employee": {
		"employees": "Employees",
		"name": "Full Name",
		"role": "Role",
		"username": "Username",
		"phone": "Phone",
		"email": "Email",
		"password": "Password",
		"branch": "Branch",
		"login": "Login",
		"search_placeholder": "Search by Name",
		"add_button": "Add",
		"add_title": "Add Employee",
		"table_title": "Responsible Persons",
		"select_role": "Role",
		"select_object": "Object",
		"object": "Object",
		"projects": "Projects",
		"objects": "Objects",
		"timesheet": "Timesheet",
		"timesheet_id": "ID",
		"timesheet_title": "Value",
		"timesheet_start": "Start",
		"timesheet_end": "End",
		"delete": "Delete",
		"confirm_delete_text": "Delete Employee?",
		"edit_button": "Edit",
		"edit_title": "Edit Employee",
		"tasks": "Tasks",
		"all_tasks": "All Tasks",
		"post": "Position",
		"main_specialist": "Key Specialist"
	},
	"task": {
		"id": "ID",
		"title": "Title",
		"start": "Start",
		"end": "End",
		"category": "Category",
		"description": "Description",
		"tasks": "Tasks",
		"add": "Add Task",
		"update": "Edit Task",
		"delete": "Delete",
		"confirm_delete_text": "Delete Task?",
		"date_start": "Start Date",
		"date_complete": "Completion Date",
		"type": "Type",
		"status": "Status",
		"level": "Priority Level",
		"object": "Object",
		"repeat": "Repeat",
		"result": "Execution Result",
		"created_at": "Created",
		"employee": "Responsible Person",
		"pt": "Ft",
		"ptg": "Ftg",
		"npt": "Nft",
		"nptg": "Nftg",
		"to": "Tnf"
	},
	"calendar": {
		"check_all": "Select All",
		"select_date": "Select Date",
		"all_day": "All Day",
		"add_button": "Add"
	},
	"prescription": {
		"prescription": "Prescription",
		"employee": "Technical Supervision",
		"title": "Title",
		"created_at": "Created",
		"resolve_before": "Resolve By",
		"extended_until": "Extended Until",
		"resolved": "Actual Resolution",
		"description": "Description",
		"coords": "Coordinates",
		"extend_reason": "Reason for Extension",
		"status": "Status",
		"add_button": "Add",
		"edit_button": "Edit",
		"edit_title": "Edit Prescription",
		"add_title": "Add Prescription"
	},
	"profile": {
		"profile": "Profile",
		"logout": "Logout",
		"edit_button": "Edit",
		"edit_title": "Edit Profile"
	},
	"wiki": {
		"category_title": "Title",
		"category_add_button": "Add",
		"category_edit_button": "Edit",
		"category_edit_title": "Edit Category",
		"category_add_title": "Add Category",
		"article_title": "Title",
		"article_add_button": "Add",
		"article_edit_button": "Edit",
		"article_add_title": "Add Article",
		"article_edit_title": "Edit Article",
		"article_content": "Content"
	},
	"settings": {
		"title": "Title",
		"key": "Key",
		"priorities": "Object Priority",
		"statuses": "Object Status",
		"types": "Object Type",
		"value": "Value",
		"color": "Color",
		"add_button": "Add",
		"edit_button": "Edit",
		"edit_title": "Edit Setting",
		"add_title": "Add Setting"
	},
	"branch": {
		"branches": "Branches",
		"details": "Details",
		"projects": "Projects",
		"add": "Add Branch",
		"title": "Title",
		"logo": "Logo",
		"contacts": "Contacts",
		"coordinator_name": "Coordinator",
		"coordinator_post": "Coordinator Position",
		"edit_title": "Edit Branch",
		"add_title": "Add Branch"
	},
	"checklist": {
		"checklists": "Checklists",
		"templates": "Templates",
		"title": "Title",
		"created_at": "Created",
		"updated_at": "Edited",
		"day_of_week": "Day of the Week",
		"employee": "Filled By",
		"notes": "Notes",
		"corrections": "Corrections",
		"questions": "Questions",
		"add_button": "Add",
		"add_edit": "Edit",
		"add_title": "Add Checklist",
		"edit_title": "Edit Checklist",
		"value_ok": "Ok",
		"value_not_ok": "Not Ok",
		"value_nz": "Nz",
		"add_template_button": "Add",
		"add_template_edit": "Edit",
		"add_template_title": "Add Template",
		"edit_template_title": "Edit Template",
		"repeat": "Repeat",
		"repeat_day": "Repeat Day",
		"organization": "Contracting Organization",
		"date": "Date",
		"employees": "Employees"
	},
	"day_of_week": {
		"mon": "Monday",
		"tue": "Tuesday",
		"wed": "Wednesday",
		"thu": "Thursday",
		"fri": "Friday",
		"set": "Saturday",
		"sun": "Sunday"
	},
	"repeats": {
		"onetime": "One-time",
		"everyday": "Daily",
		"everyweek": "Weekly",
		"everymonth": "Monthly"
	},
	"timesheets": {
		"pt": "Ft",
		"ptg": "Ftg",
		"npt": "Nft",
		"nptg": "Nftg",
		"to": "Tnf"
	}
}